import React from 'react';
import { Layout } from '../components/Layout';
import HeaderArea from '../components/home/HeaderArea';
import { useAuthStore } from '../store/useAuthStore';
import { AlertCircle } from 'lucide-react';

export const Announcement = () => {
  const { user } = useAuthStore();

  // Static announcement content
  const announcement = {
    title: "Welcome to CatsCoin!",
    details: [
      "<strong>Welcome to the CatsCoin community!</strong>",
      "We are thrilled to have you here. Our mission is to create a collaborative and innovative environment for all our members.",
      "Stay tuned for exciting updates, events, and opportunities to engage with fellow enthusiasts.",
      "Thank you for joining us and being a part of this journey.",
    ],
  };

  return (
    <Layout active={5} hideheaderbg={true}>
      <HeaderArea user={user} />

      <div className="w-full rounded-[23px] px-2 my-2">
        <div className="rounded-xl bg-main border border-main p-4 shadow-lg">
          {/* Title Section */}
          <div className="flex items-center gap-4 mb-8">
            <div className="flex items-center justify-center bg-main rounded-full p-3">
              <AlertCircle size={28} color="#F4BE0F" />
            </div>
            <h1 className="text-2xl font-bold text-white/90">{announcement.title}</h1>
          </div>

          {/* Content Section */}
          <div className="text-white text-base font-medium leading-7 space-y-6">
            {announcement.details.map((paragraph, index) => (
              <p
                key={index}
                dangerouslySetInnerHTML={{ __html: paragraph }} // Render HTML for N: B:
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
