import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Layout } from './components/Layout';
import { TapArea } from './components/tap/TapArea';
import { useTapGame } from './hooks/useTapGame';
import { Loading } from './pages/Loading';
import HeaderArea from './components/home/HeaderArea';
import { ErrorDisplay } from './components/tap/ErrorDisplay';
import { DailyLimitIndicator } from './components/tap/DailyLimitIndicator';

import TopBoxSection from './components/TopBoxSection';
import { useGameStore } from './store/gameStore';
import { useGame } from './context/GamContext';
import { useAuthStore } from './store/useAuthStore';
import { useNavigate } from 'react-router-dom';
import CredentialUpdateModal from './components/modals/CredentialUpdateModal';
import { useLatestOfferNotice } from './api/offerNotices';
import NoticeModal from './components/modals/NoticeModal';
import ReferralCodeModal from './components/modals/ReferralCodeModal';
import { useUpdateReferralCode } from './api/credential';
import { useNotification } from './hooks/useNotification';
import { EarningProgress } from './components/tap/EarningProgress';
import { useBurnProgress } from './api/rank';
// import { SmallDailyReward } from './components/SmallDailyReward';
export const TapGame = () => {
  const { gameState, handleTap, isTapInfoLoading, isError } = useGame();
  const [isNoticeOpen, setNoticeOpen] = useState(true);
  const { showNotification } = useNotification();

  const { data: burnProgressData } = useBurnProgress();

  const { data: notice } = useLatestOfferNotice();
  console.log('notice', notice);

  const { user, modalShow, setModalShow } = useAuthStore();
  const navigate = useNavigate();
const [isReferrakModalOpen, setIsReferralModalOpen] = useState(() => {
  const referralSet = localStorage.getItem("referralset");
  return !(referralSet === "true") && user && (user?.sponsor === null || user?.sponsor === undefined);
});

  const [referralLoading, setReferralLoading] = useState(false);
  const { mutate: updateReferralCode, status } = useUpdateReferralCode();

  const tapAnimationRef = useRef(null);
  // console.log('gameState', gameState);



  // Handle confirmation and navigate to update credentials page
  const handleModalConfirm = () => {
    setModalShow(false);
    navigate("/update-credentials");
  };

  // Close the modal without navigating
  const handleModalClose = () => {
    setModalShow(false);
    setNoticeOpen(true);
  };
  const handleTapWithAnimation = (event) => {
    const { clientX, clientY } = event;

    if (gameState.isLimitReached) {
      handleTap(event);
      return;
    }
    tapAnimationRef.current?.showNumberAnimation(clientX, clientY, gameState.coinsPerTap);

    handleTap(event);
  };
  console.log('gameStatesss', gameState);
  const handleNoticeClose = () => {
    setNoticeOpen(false);
  };

  const handleReferralSubmit = async (referralCode) => {
    if (!referralCode.trim()) {
      showNotification("error", "Referral code cannot be empty!");
      return;
    }

    setReferralLoading(true); // Start loading
    updateReferralCode(referralCode, {
      onSuccess: (data) => {
        showNotification("success", "Referral code updated successfully!");
        localStorage.setItem("referralset", true); // Update referral code in local storage
        setIsReferralModalOpen(false); // Close modal after success
      },
      onError: (error) => {
        showNotification("error", error?.response?.data?.message || "Failed to update referral code.");
      },
      onSettled: () => {
        setReferralLoading(false); // Stop loader after completion
      },
    });
  };

  if (isTapInfoLoading) return <Loading />;
  if (isError) return <div>Error loading tap information</div>;

  return (
    <Layout active={1} hideheaderbg={true} scroll={false}>
      <HeaderArea user={user} gameState={gameState} />
      <ReferralCodeModal
        isOpen={isReferrakModalOpen}
        onSubmit={handleReferralSubmit}
        loading={referralLoading}
      />
      {/* <CredentialUpdateModal
        isOpen={modalShow && !isReferrakModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        loading={false}
      /> */}
      {/* <NoticeModal
        isOpen={isNoticeOpen && !!notice}
        onClose={handleNoticeClose}
        title={notice?.offerTitle}
        message={notice?.offerMessage}
        loading={false} // Set to true if you have an async action
      /> */}
      <ErrorDisplay gameState={gameState} />

      <TopBoxSection gameState={gameState} />
      {/* <EarningProgress
        burnProgressData={burnProgressData}
      /> */}
      <TapArea
        onTap={handleTapWithAnimation}
        tapInfo={{
          dailyCoinsEarned: gameState.dailyCoinsEarned,
          dailyLimit: gameState.dailyLimit,
          coinPertap: gameState.coinsPerTap,
          isLimitReached: gameState.isLimitReached,
        }}
        animationRef={tapAnimationRef}
      />

      <DailyLimitIndicator
        dailyCoinsEarned={gameState.dailyCoinsEarned}
        dailyLimit={gameState.dailyLimit}
      />

    </Layout>
  );
};